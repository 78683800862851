import React, { useState, useEffect, useRef } from "react";
import "../styles/conversation-modal.css";
import ApiService from "../api/apiService";
import showSwal from "./swal";
import { common } from "../util/common";
import loader2 from "../images/loader_2.gif";

function ConversationModal(props, { onClose }) {
  const { showHideConversationModal } = props;
  const [conversationName, setConversationName] = useState("");
  const [members, setMembers] = useState([]);
  const [memberIds, setMemberIds] = useState([]);
  const [memberInput, setMemberInput] = useState("");
  const [userSuggestions, setUserSuggestions] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [imageData, setImageData] = useState(null);

  const loaderRef = useRef(null);

  const [formSubmitting, setFormSubmitting] = useState(false);

  useEffect(() => {
    const fetchUserNames = async () => {
      try {
        const response = await ApiService.users.getAllusers();
        const { success, users } = response;
        if (success) {
          setAllUsers(users);
        } else {
          showSwal(common.errors.CONVERSATION_FETCH_USERNAME_ERROR);
        }
      } catch (error) {
        console.error("Error fetching user names:", error);
      }
    };

    fetchUserNames();
  }, []);

  const addMember = (member) => {
    if (member.display_name.trim() !== "" && !members.includes(member.display_name)) {
      setMembers([...members, member.display_name.trim()]);
      setMemberIds([...memberIds, member.id]);
      setMemberInput("");
      setUserSuggestions([]);
    }
  };

  const removeMember = (index) => {
    setMembers(members.filter((_, i) => i !== index));
    setMemberIds(memberIds.filter((_, i) => i !== index));
  };

  const handleInputChange = (e) => {
    const input = e.target.value;
    setMemberInput(input);
    if (input) {
      const suggestions = allUsers.filter((user) => user.display_name.toLowerCase().includes(input.toLowerCase()));
      setUserSuggestions(suggestions);
    } else {
      setUserSuggestions([]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formSubmitting) return;
    loaderRef.current.style.display = "inline";
    setFormSubmitting(true);
    const formData = new FormData();
    const payload = {
      name: conversationName,
      conversation_member_ids: memberIds,
      is_group: true,
    };
    formData.append("conversation_image", imageData);
    formData.append("data", JSON.stringify(payload));

    const _conversation = await ApiService.conversation.createConversation(formData);

    const { success } = _conversation;
    if (success) {
      showSwal(common.success.CONVERSATION_CREATED);
    } else {
      showSwal(common.errors.CREATE_CONVERSATION_ERROR);
    }

    loaderRef.current.style.display = "none";
    setFormSubmitting(false);
    showHideConversationModal();
  };

  return (
    <div className='modal-container'>
      <div className='modal-dialog'>
        <button className='close-btn' onClick={showHideConversationModal}>
          ✕
        </button>
        <form onSubmit={handleSubmit}>
          <div className='form-group'>
            <label>Conversation Image</label>
            <input type='file' onChange={(e) => setImageData(e.target.files[0])} />
          </div>
          <div className='form-group'>
            <label>Conversation Name</label>
            <input
              type='text'
              value={conversationName}
              onChange={(e) => setConversationName(e.target.value)}
              required
            />
          </div>
          <div className='form-group'>
            <label>Add Members</label>
            <div className='add-member-container'>
              <input type='text' value={memberInput} onChange={handleInputChange} />
            </div>
            {userSuggestions.length > 0 && (
              <ul className='suggestions-list'>
                {userSuggestions.map((user, index) => (
                  <li key={index} onClick={() => addMember(user)}>
                    {user.display_name}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <ul className='member-list'>
            {members.map((member, index) => (
              <li key={index} className='member-item'>
                {member}
                <button type='button' onClick={() => removeMember(index)} className='remove-member-btn'>
                  ✕
                </button>
              </li>
            ))}
          </ul>
          <div className='form-group'>
            <button type='submit' className='submit-btn'>
              {!formSubmitting && "Create Conversation"}
              <img src={loader2} ref={loaderRef} style={{ display: "none" }} alt='Loading...' />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ConversationModal;
