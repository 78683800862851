import "../styles/style.css";
import "font-awesome/css/font-awesome.min.css";
import emptyImage from "../images/empty.jpg";
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ChatContext } from "../context/contexts";

function MessageHead(props) {
  const Chat = useContext(ChatContext);
  const [visibleDropdown, setVisibleDropdown] = useState(true);
  const navigate = useNavigate();

  const {
    conversationData,
    setMobileChatDetailShow,
    showHideSearchmodal,
    showHideViewMembersModal,
    showHideViewConversationFilesModal,
    conversationUserPermission,
    showHideEditConversationModal,
    showHideViewActivityLogsModal,
  } = props;
  let conversationImage;
  if (conversationData) {
    const FINAL_RESOURCES_URL = `${process.env.REACT_APP_IMAGES_DATA_RESOURCES_URL}/${conversationData?.conversation?.image}`;
    conversationImage = conversationData?.conversation?.image?.includes("empty-filler-image.jpg")
      ? emptyImage
      : FINAL_RESOURCES_URL;
  } else {
    conversationImage = emptyImage;
  }

  const toggleDropdown = () => {
    setVisibleDropdown(!visibleDropdown);
  };

  const handleBackEvent = () => {
    setMobileChatDetailShow(false);
    navigate("/");
  };

  const toggleSearchmodal = () => {
    if (conversationData) showHideSearchmodal();
  };
  const toggleEditConversationModal = () => {
    if (conversationData) showHideEditConversationModal();
  };

  const toggleViewMembersModal = () => {
    if (conversationData) showHideViewMembersModal();
  };
  const toggleViewConversationFilesModal = () => {
    if (conversationData) showHideViewConversationFilesModal();
  };

  const toggleViewActivityLogModal = () => {
    if (conversationData) showHideViewActivityLogsModal();
  };
  const isSelfAdmin = conversationUserPermission?.is_admin || false;

  return (
    <>
      <button className='message__back-btn' aria-label='Go back' onClick={handleBackEvent}>
        <i className='fa fa-arrow-left message__back-btn-icon' aria-hidden='true'></i>
      </button>
      <img src={conversationImage} alt='Seller image' className='message__head-img' />
      <div className='message__head-details'>
        <span className='message__head-name'>{conversationData?.conversation?.name}</span>
      </div>
      <button className='message__btn-more js-message_btn_more' onClick={toggleDropdown}>
        <div className='message__btn-more-icon'>
          <i className='fa fa-ellipsis-h'></i>
        </div>
        <ul className={`message__dropdown ${visibleDropdown ? "u-hidden" : ""}`}>
          {isSelfAdmin && (
            <li className='message__dropdown-item' onClick={toggleEditConversationModal}>
              <a href='#' className='message__dropdown-link'>
                <i className='fa fa-cog' aria-hidden='true'></i>
                &nbsp; Group Settings
              </a>
            </li>
          )}
          <li className='message__dropdown-item' onClick={toggleSearchmodal}>
            <a href='#' className='message__dropdown-link'>
              <i className='fa fa-search'></i>
              &nbsp; Search Chat
            </a>
          </li>
          <li className='message__dropdown-item' onClick={toggleViewActivityLogModal}>
            <a href='#' className='message__dropdown-link'>
              <i className='fa fa-bars'></i>
              &nbsp; Activity Log
            </a>
          </li>
          <li className='message__dropdown-item' onClick={toggleViewConversationFilesModal}>
            <a href='#' className='message__dropdown-link'>
              <i className='fa fa-files-o'></i>
              &nbsp; View Files
            </a>
          </li>
          <li className='message__dropdown-item' onClick={toggleViewMembersModal}>
            <a href='#' className='message__dropdown-link'>
              <i className='fa fa-users'></i>
              &nbsp; View Members
            </a>
          </li>
        </ul>
      </button>
    </>
  );
}

export default MessageHead;
