import React, { useState, useEffect, useRef } from "react";
import Header from "../components/header";
import Main from "../components/main";
import ApiService from "../api/apiService";
import Websocket from "../hooks/ws";
import { WebSocketContext, ChatContext } from "../context/contexts";
import showSwal from "../components/swal";
import { common } from "../util/common";
import notifSound from "../sounds/notif-sound.mp3";
import { isMobile } from "react-device-detect";
import Loader from "../components/reusable/loader";

function ChatIndex(props) {
  const { userDetails, linkConversation } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [viewChangePasswordModal, setViewChangePasswordModal] = useState(false);

  const [attachedListener, setAttachedListener] = useState(false);

  const [queryPageSize, setQueryPageSize] = useState(30);
  const [storedConversationId, setConversationId] = useState(null);
  const [conversationData, setConversationData] = useState(null);
  const [conversationUserPermission, setConversationUserPermission] = useState(null);
  const [isPermittedToChat, setIsPermittedToChat] = useState(true);
  const [conversationMembersData, setConversationMembers] = useState([]);
  const [messagesData, setMessagesData] = useState(null);

  const [wsMessagesData, setWsMessagesData] = useState([]);

  const [wsDeleteActionsMessageId, setWsDeleteActionsMessageId] = useState(null);

  const [wsTheradMsgs, setWsThreadMsgs] = useState(null);

  const [wsReactionMessageId, setWsReactionMessageId] = useState(null);
  const [wsDeletedReactionMessageId, setWsDeletedReactionMessageId] = useState(null);
  const [wsReactionMessageDetail, setWsReactionMessageDetail] = useState(null);

  const [hasSelectedChat, setHasSelectedChat] = useState(false);
  const [isMobileMode, setMobileMode] = useState(false);
  const [isMobileChatDetailShow, setMobileChatDetailShow] = useState(false);
  const [chatReplyData, setChatReplyData] = useState(null);
  const messageMainRef = useRef(null);
  const queryPageSizeRef = useRef(queryPageSize);

  const [threadViewMsgId, setThreadViewMsgId] = useState(null);
  const [threadViewVisible, setThreadViewVisible] = useState(false);
  const [threadViewDetails, setThreadViewDetails] = useState(null);
  const [storedThreadMessageId, setStoredThreadMessageId] = useState(null);

  const websocketInstance = new Websocket();
  useEffect(() => {
    if (userDetails.id && userDetails.display_name) {
      setLoggedIn(true);
      websocketInstance.initConnection(userDetails.id, userDetails.display_name);
      websocketInstance.startConnectionCheck();
      if (linkConversation !== "" || linkConversation !== null) {
        connectWSRoom(linkConversation);
      }
    } else {
      setLoggedIn(false);
    }
  }, [userDetails]);

  useEffect(() => {
    let countdownTimer;
    let countdownCompleted = false;

    if (isMobile) {
      const handleVisibilityChange = () => {
        if (document.visibilityState === "visible") {
          websocketInstance.checkConnection();
        }
      };
      const startCountdown = () => {
        countdownTimer = setTimeout(() => {
          countdownCompleted = true;
        }, 25000);
      };

      const clearCountdown = () => {
        clearTimeout(countdownTimer);
        countdownCompleted = false;
      };

      const visibleListener = () => {
        if (countdownCompleted) {
          window.location.reload();
        } else {
          clearCountdown();
        }
      };

      const invisibleListener = () => {
        startCountdown();
      };

      window.addEventListener("focus", visibleListener);
      window.addEventListener("blur", invisibleListener);

      document.addEventListener("visibilitychange", handleVisibilityChange);
      return () => {
        document.removeEventListener("visibilitychange", handleVisibilityChange);
        window.removeEventListener("focus", visibleListener);
        window.removeEventListener("blur", invisibleListener);
        clearCountdown();
      };
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      const fetchConversationData = async () => {
        try {
          const result = await ApiService.conversation.getAllConversations();

          setData(result);
        } catch (error) {
          setError(error);
        }
      };

      fetchConversationData();
      const intervalId = setInterval(fetchConversationData, 2500);
      return () => clearInterval(intervalId);
    }
  }, [isLoggedIn]);

  const handleWSMsgs = (wsMsg) => {
    const __notif = new Audio(notifSound);
    if (wsMsg.type === "user-chat" || wsMsg.type === "user-actions-chat" || wsMsg.type === "user-thread-chat") {
      if (wsMsg.type === "user-thread-chat") {
        handleThreadChat(wsMsg.message);
      } else {
        setWsMessagesData((prevState) => [...prevState, wsMsg.message]);
        if (userDetails.id !== wsMsg.message.sender_id) {
          // __notif.play().catch((err) => {});
        }

        if (wsMsg.message.action !== "DELETE") {
          setTimeout(() => {
            scrollToBottom();
          }, 300);
        } else {
          handleMsgDeleted(wsMsg.message.chat_id);
        }
      }
    } else if (wsMsg.type === "user-reactions-chat") {
      const reactionType = wsMsg?.message?.type;
      if (reactionType === "created") {
        setWsReactionMessageId(wsMsg.message.id);
      } else {
        setWsDeletedReactionMessageId(wsMsg.message.id);
      }
      setWsReactionMessageDetail(wsMsg.message);
    }
  };

  const handleMsgDeleted = (chat_id) => {
    setWsDeleteActionsMessageId(chat_id);
  };

  const handleThreadChat = (chat_thread_detail) => {
    setWsThreadMsgs(chat_thread_detail);
  };

  const handleWSSidebarMsgs = async () => {
    try {
      const result = await ApiService.conversation.getAllConversations();
      setData(result);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const connectWSRoom = (conversation_id) => {
    websocketInstance.connectRoom(conversation_id);
    if (!attachedListener) {
      websocketInstance.setupListeners((data) => {
        handleWSMsgs(data);
      });
      websocketInstance.setupDisconnectListener();
      setAttachedListener(true);
    }
  };

  const handleSetConversationData = (conversationDetail) => {
    const userDetails = localStorage.getItem("userDetails");
    const _userDetails = JSON.parse(userDetails);
    setConversationData(conversationDetail);
    const userPermissions = conversationDetail?.conversation.members.find(
      (conversationMember) => conversationMember.user_id === _userDetails.id
    );
    setConversationUserPermission(userPermissions);
    setIsPermittedToChat(!userPermissions.is_muted);
    localStorage.setItem("selectedConversationId", conversationDetail.conversation.id);
  };

  const scrollToBottom = () => {
    const messageMain = messageMainRef.current;
    if (messageMain) {
      messageMain.scrollTo({
        top: messageMain.scrollHeight - messageMain.clientHeight,
        behavior: "auto",
      });
    }
  };

  const setConversationDetail = async (conversationId, querySize = 30) => {
    setMessagesData(null);
    setConversationData(null);
    setLoading(true);
    setQueryPageSize(querySize);

    try {
      const conversationDetail = await ApiService.conversation.getConversationDetail(conversationId);
      const messagesDetail = await ApiService.message.getConversationMessages(conversationId);
      const { success: conversationSuccess } = conversationDetail;
      const { success: messagesSuccess } = messagesDetail;

      if (!conversationSuccess || !messagesSuccess) {
        setLoading(false);
        showSwal(common.errors.SET_CONVERSATION_DATA_ERROR).then(() => {
          window.location.reload();
        });
      }

      setConversationId(conversationId);
      handleSetConversationData(conversationDetail);
      setConversationMembers(conversationDetail.conversation.members);
      setMessagesData(messagesDetail);
      setHasSelectedChat(true);
      setChatReplyData(null);
      setWsMessagesData([]);

      isMobileMode ? setMobileChatDetailShow(true) : setMobileChatDetailShow(false);
    } catch (error) {
      setError(error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  };

  const reSetConversationDetail = async (conversationId, queryPageSize, callback) => {
    setMessagesData(null);
    setConversationData(null);
    setLoading(true);
    setQueryPageSize(queryPageSize);
    try {
      const conversationDetail = await ApiService.conversation.getConversationDetail(conversationId);
      const messagesDetail = await ApiService.message.getConversationMessages(conversationId, 1, queryPageSize);

      const { success: conversationSuccess } = conversationDetail;
      const { success: messagesSuccess } = messagesDetail;

      if (!conversationSuccess || !messagesSuccess) {
        setLoading(false);
        showSwal(common.errors.SET_CONVERSATION_DATA_ERROR).then(() => {
          window.location.reload();
        });
        return;
      }
      setConversationId(conversationId);
      handleSetConversationData(conversationDetail);
      setConversationMembers(conversationDetail.conversation.members);
      setMessagesData(messagesDetail);
      setHasSelectedChat(true);
      setChatReplyData(null);
      setWsMessagesData([]);
      isMobileMode ? setMobileChatDetailShow(true) : setMobileChatDetailShow(false);
      setLoading(false);
      callback(true);
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  const updateConversationData = async (
    conversationId,
    isViewMoreClicked,
    oldScrollHeight,
    pageSize = queryPageSize
  ) => {
    try {
      setLoading(true);
      let conversationDetail, messagesDetail;
      if (isViewMoreClicked) {
        return new Promise(async (resolve, reject) => {
          const messageMain = document.querySelector(".message__main");
          const oldScrollHeight = messageMain.scrollHeight;
          const oldScrollTop = messageMain.scrollTop;
          messagesDetail = await ApiService.message.getConversationMessages(storedConversationId, 1, pageSize);

          setMessagesData(messagesDetail);
          setHasSelectedChat(true);
          setChatReplyData(null);
          isMobileMode ? setMobileChatDetailShow(true) : setMobileChatDetailShow(false);
          setTimeout(() => {
            // const newScrollHeight = messageMain.scrollHeight;
            // messageMain.scrollTop = newScrollHeight - oldScrollHeight + oldScrollTop;
            setLoading(false);
            resolve();
          }, 400);
        });
      } else {
        conversationDetail = await ApiService.conversation.getConversationDetail(conversationId);
        messagesDetail = await ApiService.message.getConversationMessages(conversationId);
        const { success: conversationSuccess } = conversationDetail;
        const { success: messagesSuccess } = messagesDetail;
        if (!conversationSuccess || !messagesSuccess) {
          setLoading(false);
          showSwal(common.errors.UPDATE_CONVERSATION_DATA_ERROR).then(() => {
            window.location.reload();
          });
        }
        setConversationId(conversationId);
        handleSetConversationData(conversationDetail);
        setConversationMembers(conversationDetail.conversation.members);
        setMessagesData(messagesDetail);
        setHasSelectedChat(true);
        setChatReplyData(null);
        setWsMessagesData([]);
        isMobileMode ? setMobileChatDetailShow(true) : setMobileChatDetailShow(false);
        setLoading(false);
      }
    } catch (error) {
      setError(error);
    }
  };

  const showHideChangePasswordModal = () => {
    setViewChangePasswordModal(!viewChangePasswordModal);
  };

  const handleThreadViewMsgId = (id, conversation_id = null) => {
    setThreadViewMsgId(id);
    fetchThreadDetails(id, conversation_id);
  };

  const handleThreadViewVisible = () => {
    setThreadViewVisible(!threadViewVisible);
  };

  const fetchThreadDetails = async (message_id, conversation_id = null) => {
    setLoading(true);
    const finalConversationId = conversation_id ? conversation_id : storedConversationId;
    const threadDetails = await ApiService.message.getThreadMessages(finalConversationId, message_id);
    const { success, thread_detail } = threadDetails;
    if (!success) {
      setLoading(false);
      showSwal(common.errors.FETCH_THREAD_ERROR);
    }
    setThreadViewDetails(thread_detail);
    setStoredThreadMessageId(message_id);
    handleThreadViewVisible(true);
    setLoading(false);
  };
  const reFetchThreadDetails = async (message_id) => {
    const threadDetails = await ApiService.message.getThreadMessages(storedConversationId, message_id);
    const { success, thread_detail } = threadDetails;
    if (!success) {
      showSwal(common.errors.FETCH_THREAD_ERROR);
    }
    setThreadViewDetails(thread_detail);
    setLoading(false);
  };

  useEffect(() => {
    if (isLoggedIn && storedThreadMessageId && threadViewVisible) {
      const intervalId = setInterval(() => reFetchThreadDetails(storedThreadMessageId), 5000);
      return () => clearInterval(intervalId);
    }
  }, [isLoggedIn, storedThreadMessageId, threadViewVisible]);

  return (
    <>
      <WebSocketContext.Provider value={{ websocketInstance: websocketInstance, connectWSRoom: connectWSRoom }}>
        <ChatContext.Provider
          value={{
            setConversationDetail: setConversationDetail,
            storedConversationId: storedConversationId,
            conversationData: conversationData,
            conversationUserPermission: conversationUserPermission,
            isPermittedToChat: isPermittedToChat,
            conversationMembersData: conversationMembersData,
            messagesData: messagesData,
            hasSelectedChat: hasSelectedChat,
            setChatReplyData: setChatReplyData,
            chatReplyData: chatReplyData,
            isMobileChatDetailShow: isMobileChatDetailShow,
            setMobileMode: setMobileMode,
            messageMainRef: messageMainRef,
            updateConversationData: updateConversationData,
            setQueryPageSize: setQueryPageSize,
            queryPageSize: queryPageSize,
            queryPageSizeRef: queryPageSizeRef,
            setMessagesData: setMessagesData,
            setMobileChatDetailShow: setMobileChatDetailShow,
            isMobileMode: isMobileMode,
            scrollToBottom: scrollToBottom,
            wsMessagesData: wsMessagesData,
            wsDeleteActionsMessageId: wsDeleteActionsMessageId,
            handleThreadViewMsgId,
            threadViewMsgId,
            handleThreadViewVisible,
            threadViewVisible,
            threadViewDetails,
            reFetchThreadDetails,
            wsTheradMsgs,
            reSetConversationDetail,
            wsReactionMessageId,
            wsReactionMessageDetail,
            wsDeletedReactionMessageId,
            setWsReactionMessageId,
            isLoggedIn,
            isLoading: loading,
          }}
        >
          <Loader isLoading={loading} />
          <Header
            userDetails={userDetails}
            showHideChangePasswordModal={showHideChangePasswordModal}
            viewChangePasswordModal={viewChangePasswordModal}
          />
          <Main
            conversations={data?.conversations}
            userDetails={userDetails}
            linkConversation={linkConversation}
            showHideChangePasswordModal={showHideChangePasswordModal}
            viewChangePasswordModal={viewChangePasswordModal}
          />
        </ChatContext.Provider>
      </WebSocketContext.Provider>
    </>
  );
}

export default ChatIndex;
