import React, { useEffect, useState, useContext } from "react";
import notificationIcon from "../../images/notification.png";
import emptyImage from "../../images/empty.jpg";
import ApiService from "../../api/apiService";
import showSwal from "../swal";
import { common } from "../../util/common";
import { ChatContext } from "../../context/contexts";
import { convertTo12HourPlusDateFormat, trimWord, formatBoldText } from "../../util/helperFunctions";
import verifyToken from "../../hooks/verifyToken";
import bellNotifSound from "../../sounds/bell-notif.mp3";

function NotificationBell() {
  const Chat = useContext(ChatContext);
  const [showNotifications, setShowNotifications] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationList, setNotificationList] = useState([]);

  useEffect(() => {
    const getNotifications = async () => {
      const notifications = await ApiService.users.getAllNotifications();
      const { success, notifications: notificationList } = notifications;
      if (!success) return window.location.reload();
      const { count, unread_count, notifications: _notifications } = notificationList;
      setNotificationCount(unread_count);
      setNotificationList(_notifications);
    };
    const intervalId = setInterval(() => getNotifications(), 2500);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const __notif = new Audio(bellNotifSound);
    if (notificationCount > 0) {
      __notif.play().catch((err) => {});
    }
  }, [notificationCount]);

  const handleBellClick = () => {
    setShowNotifications(!showNotifications);
  };

  const handleReadDeleteClick = async (notification_id, action) => {
    const response = await ApiService.notifications.readDeleteNotification({ action }, notification_id);
    const { success } = response;
    if (!success) return showSwal(common.errors.UPDATE_NOTIFICATION_ERROR);
  };

  const handleNotificationClick = async (
    notification_id,
    conversation_id,
    message_id,
    reply_to_thread_message_id,
    notification_type,
    request_notification_form_request_id
  ) => {
    await handleReadDeleteClick(notification_id, "read");

    if (notification_type === "Removed") {
      return;
    } else if (notification_type === "Mute") {
      window.location.href = `/conversations/${conversation_id}`;
    } else if (request_notification_form_request_id !== null && request_notification_form_request_id !== "") {
      window.location.href = `/requests?p=view-request-detail&t=ePR&r=${request_notification_form_request_id}`;
    } else {
      const msgIndex = await ApiService.message.getMessagePosition(conversation_id, message_id);
      const { success, response } = msgIndex;
      const { index, query } = response;
      if (!success) return showSwal(common.errors.CLICK_NOTIFICATION_ERROR);
      if (reply_to_thread_message_id !== "" && reply_to_thread_message_id !== null) {
        window.location.href = `/conversations/${conversation_id}?q=${message_id}&i=${index}&_q=${query}&_t=${reply_to_thread_message_id}&_tr=true`;
      } else {
        window.location.href = `/conversations/${conversation_id}?q=${message_id}&i=${index}&_q=${query}`;
      }
    }
  };

  const FINAL_RESOURCES_URL = `${process.env.REACT_APP_IMAGES_DATA_RESOURCES_URL}/`;

  return (
    <div className='profile-container notification-container'>
      <img src={notificationIcon} alt='Notification Icon' className='notification-icon' onClick={handleBellClick} />
      {notificationCount > 0 && (
        <div className='notification-count-container'>
          <div className='notification-count'>{notificationCount}</div>
        </div>
      )}
      {showNotifications && (
        <div className='notification-list'>
          {notificationList.length > 0 ? (
            notificationList.map((notification, index) => (
              <div
                className={`notification-item ${notification.is_read ? "" : "notification-item-unread"}`}
                key={index}
              >
                <div
                  className='notification--item'
                  onClick={(e) => {
                    if (notification.notification_type === "removed") {
                      return;
                    }
                    handleNotificationClick(
                      notification.id,
                      notification.conversation_id,
                      notification.message_id,
                      notification.reply_to_thread_message_id,
                      notification.notification_type,
                      notification.request_notification_form_request_id
                    );
                  }}
                >
                  <img
                    src={`${
                      notification.conversation_details.image
                        ? `${FINAL_RESOURCES_URL}${notification.conversation_details.image}`
                        : emptyImage
                    }`}
                    alt='User'
                    className='notification-user-image'
                  />
                  <div>
                    <div className='notification-message'>
                      {renderNotificationMessage(
                        notification.notification_type,
                        notification.message_details,
                        notification.notification_sender_details,
                        notification.conversation_details
                      )}
                    </div>
                    <small>{convertTo12HourPlusDateFormat(notification.createdAt)}</small>
                  </div>
                </div>
                <div className='notification-actions'>
                  {!notification.is_read && (
                    <div
                      className='notification-action-check notif-action'
                      onClick={(e) => handleReadDeleteClick(notification.id, "read")}
                    >
                      <i className='fa fa-check-square-o fa-2x' aria-hidden='true'></i>
                    </div>
                  )}
                  <div
                    className='notification-action-trash notif-action'
                    onClick={(e) => handleReadDeleteClick(notification.id, "delete")}
                  >
                    <i className='fa fa-trash-o fa-2x' aria-hidden='true'></i>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className='notification-item'>
              <span>No notifications found.</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

const renderNotificationMessage = (notificationType, messageDetails, notifSender, conversationDetails) => {
  const notification_type = notificationType.toLowerCase() || "";

  let returnedValue = "";

  switch (notification_type) {
    case "reaction":
      returnedValue = (
        <span>
          <b>{notifSender.display_name}</b> reacted to your message '
          {formatBoldText(trimWord(messageDetails.content, 15))}'.
        </span>
      );
      break;
    case "mention":
      returnedValue = (
        <span>
          <b>{notifSender.display_name}</b> mentioned you in a chat '
          {formatBoldText(trimWord(messageDetails.content, 15))}'.
        </span>
      );
      break;
    case "thread mention":
      returnedValue = (
        <span>
          <b>{notifSender.display_name}</b> mentioned you in a thread reply '
          {formatBoldText(trimWord(messageDetails.content, 15))}'.
        </span>
      );
      break;
    case "mute":
      returnedValue = (
        <span>
          <b>{notifSender.display_name}</b> muted you in the conversation '{conversationDetails.name}'.
        </span>
      );
      break;
    case "unmute":
      returnedValue = (
        <span>
          <b>{notifSender.display_name}</b> unmuted you in the conversation '{conversationDetails.name}'.
        </span>
      );
      break;
    case "removed":
      returnedValue = (
        <span>
          <b>{notifSender.display_name}</b> removed you in the conversation '{conversationDetails.name}'.
        </span>
      );
      break;
    case "new form request":
      returnedValue = (
        <span>
          <b>{notifSender.display_name}</b> submitted a form request that needs your approval.
        </span>
      );
      break;
    case "approve form request":
      returnedValue = (
        <span>
          <b>{notifSender.display_name}</b> approved a form request that now needs your approval.
        </span>
      );
      break;
    case "approved form request":
      returnedValue = (
        <span>
          <b>{notifSender.display_name}</b> approved your request. Your request is now <b>APPROVED</b>.
        </span>
      );
      break;
    case "denied form request":
      returnedValue = (
        <span>
          <b>{notifSender.display_name}</b> denied your form request.
        </span>
      );
      break;
    default:
      returnedValue = <span>UNSUPPORTED NOTIFICATION TYPE. PLEASE CONTACT SUPPORT.</span>;
  }

  return returnedValue;
};

export default NotificationBell;
